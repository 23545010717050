import "styles/pages/single-post.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumps from "components/Breadcrumps";

import { ContentSingle } from "page_components/quizy";

const Quiz = ({ pageContext }) => {
  const breadcrumps_data = [
    {
      name: "Darmowe Testy i Quizy",
      href: "/quizy/",
    },
    {
      name: pageContext?.data?.title,
    },
  ];

  return (
    <Layout>
      <Seo
        title={pageContext?.data?.seo?.title}
        description={pageContext?.data?.seo?.metaDesc}
      />
      <Breadcrumps data={breadcrumps_data} />
      <SubpageHeader title={pageContext?.data?.title} />
      <ContentSingle data={pageContext?.data} />
    </Layout>
  );
};

export default Quiz;
